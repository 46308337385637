<template>
  <div class="course">
    <HeaderInnerpage />

    <v-container class="my-3 innerpage-container">
      <v-row>
        <v-col cols="12">
          <v-breadcrumbs
            v-if="!isUserBasic"
            :items="breadcrumbsItems"
          ></v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row>
        <template v-if="apiError">
          <v-alert type="error">{{ apiError }}</v-alert>
        </template>
        <template v-else>
          <template v-if="isLoaded">
            <template v-if="course">
              <v-col cols="12" sm="3" md="3" class="order-last order-sm-first">
                <ChapterNavigation
                  :currentCourse="course.id"
                  :courseImage="course.full_image"
                  :chapters="course.chapters"
                  :continueReport="$route.query.cr"
                />
              </v-col>
              <v-col cols="12" sm="9" md="9" class="course-content">
                <h4 class="h4">{{ course.title }}</h4>
                <p class="text-body-2 py-2">
                  <span class="pr-5">
                    <v-icon color="primary">mdi-clock-outline</v-icon>
                    {{ timeConvert(course.duration) }}
                  </span>
                  <span class="pr-5">
                    <v-icon color="primary">mdi-tag-outline</v-icon>
                    {{ course.category.name }}
                  </span>
                </p>

                <template v-if="currentSectionData">
                  <!-- Display chapter or sub-chapter (page) data -->
                  <h5 class="h5 mb-5">{{ currentSectionData.title }}</h5>
                  <div v-html="wrapIframe(currentSectionData.content)"></div>

                  <!-- If a quiz id exists in currentSectionData  -->

                  <template v-if="currentSectionData.quiz_id">
                    <template v-if="isQuizLoaded">
                      <template v-if="!onLiveEditing">
                        <Quiz
                          v-if="loadQuiz"
                          :answersBackup="answersBackup"
                          :quiz="quiz"
                          :course="course"
                          :quizReady="this.isQuizReady"
                          :firstQuiz="this.firstQuizInCourse"
                          :finalQuiz="this.lastQuizInCourse"
                          :storedReport="this.storedReportId"
                          :quizIsCompleted="this.storedQuizzes"
                        />
                      </template>
                    </template>
                    <template v-else>
                      <p class="text-center text-overline mb-8">Loading Quiz</p>
                      <div class="text-center">
                        <v-progress-circular
                          indeterminate
                          color="secondary"
                        ></v-progress-circular>
                      </div>
                    </template>
                  </template>
                </template>
                <template v-else>
                  <!-- Display Course Summary -->
                  <template v-if="course.image">
                    <v-img
                      :src="course.image"
                      aspect-ratio="1"
                      class="grey lighten-2 mb-5"
                      max-height="250"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="grey lighten-2 fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="secondary"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </template>
                  <div v-html="course.summary"></div>
                </template>
                <!-- Course Controls -->
                <div
                  class="course-controls d-flex justify-space-between mb-5 mt-12"
                >
                  <template
                    v-if="
                      currentSectionData &&
                        currentSectionData.prevUrl &&
                        currentSectionData.prevUrl != 'undefined'
                    "
                  >
                    <div class="mr-auto">
                      <v-btn
                        class="mr-2 course-btn"
                        fab
                        dark
                        color="primary"
                        v-on:click="$router.push(currentSectionData.prevUrl)"
                      >
                        <v-icon dark>mdi-chevron-left</v-icon>
                      </v-btn>
                      Previous.
                    </div>
                  </template>
                  <template
                    v-if="
                      currentSectionData &&
                        currentSectionData.nextUrl &&
                        currentSectionData.nextUrl != 'undefined'
                    "
                  >
                    <div class="ml-auto">
                      Next
                      <v-btn
                        class="ml-2 course-btn"
                        fab
                        dark
                        color="primary"
                        v-on:click="$router.push(currentSectionData.nextUrl)"
                      >
                        <v-icon dark>mdi-chevron-right</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <template v-else>
                    <v-btn :to="`/quizsingle/${customQuizId}`" color="primary"
                      >Launch Quiz</v-btn
                    >
                  </template>
                </div>
              </v-col>
            </template>
          </template>
          <template v-else>
            <!-- loading -->
            <v-col cols="12">
              <p class="text-center text-overline mb-8">Loading Course</p>
              <v-progress-linear
                color="secondary"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </v-col>
          </template>
        </template>
      </v-row>
    </v-container>

    <v-dialog v-model="loadPreviousData" persistent max-width="310">
      <v-card>
        <v-card-title class="headline">
          Continue your questionnaire?
        </v-card-title>
        <v-card-text
          >You have an unfinished questionnaire saved. Click "YES" to continue
          with it or click "NO" to start a new one.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="recoverDataBackup()">
            Yes
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="
              loadPreviousData = false;
              loadQuiz = true;
            "
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showModal" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">
          Are you sure to leave?
        </v-card-title>
        <v-card-text
          >If you leave, all your progress will be saved and can be loaded
          later.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="leaveSite()">
            Yes
          </v-btn>
          <v-btn color="red darken-1" text @click="showModal = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="onLiveEditing" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">
          A user is already working on this report
        </v-card-title>
        <v-card-text>You need to wait until this user finish.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="leaveReport()">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Role } from "@/helpers/roles";
import HeaderInnerpage from "@/components/HeaderInnerpage.vue";
import ChapterNavigation from "@/components/ChapterNavigation.vue";
import Quiz from "@/components/Quiz.vue";
import UserService from "@/services/user.service";
import ReportService from "@/services/report.service";

export default {
  name: "Course",
  components: {
    HeaderInnerpage,
    ChapterNavigation,
    Quiz
  },
  data() {
    return {
      breadcrumbsItems: [
        {
          text: "Home",
          disabled: false,
          href: "/"
        },
        {
          text: "Courses",
          disabled: false,
          href: "/mycourses"
        }
      ],
      customQuizId: 0,
      showModal: false,
      nextPage: null,
      loadPreviousData: false,
      answersBackup: null,
      loadQuiz: false,

      currentChapterId: this.$route.query.chapter,
      currentPageId: this.$route.query.page,
      currentSectionId: [],
      isLoaded: false,
      isQuizLoaded: false,
      course: "",
      error: null,
      quiz: null,
      quizzesInCourse: [],
      apiError: "",

      crQuizReady: false,
      onLiveEditing: false
    };
  },
  computed: {
    isOrgAdmin() {
      let orgAdmin = false;

      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.OrgAdmin) {
          orgAdmin = true;
        }
      });
      return orgAdmin;
    },

    isUserBasic() {
      let userBasic = false;
      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.UserBasic) {
          userBasic = true;
        }
      });
      return userBasic;
    },

    currentUser() {
      return this.$store.state.auth.user;
    },
    // Data for current page or chapter
    currentSectionData: function() {
      const currentChapter = this.course.chapters?.filter(
        item => item.id == this.currentChapterId
      )[0];

      if (this.currentPageId) {
        // Return page (sub-chapter) data if we are on a page
        return currentChapter.pages?.filter(
          item => item.id == this.currentPageId
        )[0];
      } else {
        //console.log("current", currentChapter);
        return currentChapter;
      }
    },
    //find the first quiz in the course
    firstQuizInCourse: function() {
      //display vuex store of quizStatus completed in an array
      const currentQuizId = this.currentSectionData.quiz_id;
      const firstQuizId = this.quizzesInCourse[0];

      //current quiz matches last quiz in course
      let firstQuiz = false;

      if (currentQuizId == firstQuizId) {
        return (firstQuiz = true);
      } else {
        return firstQuiz;
      }
    },
    //find the last quiz in the course
    lastQuizInCourse: function() {
      //display vuex store of quizStatus completed in an array
      const currentQuizId = this.currentSectionData.quiz_id;
      const lastQuizId = this.quizzesInCourse[this.quizzesInCourse.length - 1];

      //current quiz matches last quiz in course
      let finalQuiz = false;

      if (currentQuizId == lastQuizId) {
        return (finalQuiz = true);
      } else {
        return finalQuiz;
      }
    },
    //find the stored report id

    storedReportId: function() {
      //display vuex store of quizStatus completed in an array
      const currentQuizId = this.currentSectionData.quiz_id;
      const firstQuizId = this.quizzesInCourse[0];

      //current quiz matches last quiz in course
      let storedReport = this.$store.state.quizStatus.reportId;

      if (currentQuizId == firstQuizId) {
        return (storedReport = null);
      } else {
        return storedReport;
      }
    },

    //find the stored quizzes

    storedQuizzes: function() {
      //find current quiz in vuex store

      const currentQuizId = this.currentSectionData.quiz_id;

      const storedQuizIds = this.$store.state.quizStatus.myQuizzes.map(function(
        obj
      ) {
        return obj.quizId;
      });

      //current quiz matches last quiz in course
      let quizIsCompleted = false;

      if (storedQuizIds.includes(currentQuizId)) {
        return (quizIsCompleted = true);
      } else {
        return quizIsCompleted;
      }
    },

    isQuizReady: function() {
      if (this.$route.query.cr) {
        return this.crQuizReady;
      } else {
        const completedQuizzes = Object.keys(
          this.$store.state.quizStatus.completed
        );
        const completedQuizzesInt = completedQuizzes.map(i => Number(i));

        let quizIsReadyToStart = true;

        const conditionalParents = this.quiz?.conditional_parent.map(function(
          conditional
        ) {
          return conditional.parent_quiz_id;
        });

        // if current quiz has conditional parent quizzes
        if (conditionalParents && conditionalParents.length > 0) {
          // check if the conditional parent quiz id is in the vuex store of completed quizzes
          if (
            conditionalParents.every(item => completedQuizzesInt.includes(item))
          ) {
            //conditional parent id has been found in completed quizzes - access granted
            //get stored report id

            return (quizIsReadyToStart = true);
          } else {
            //conditional parent id has not been found in completed quizzes - access denied

            return (quizIsReadyToStart = false);
          }
        } else {
          //current quiz does not have a conditional parent quiz - access granted
          return quizIsReadyToStart;
        }
      }
    }
  },

  created() {
    if (!this.currentUser) {
      this.$router.push("/");
    }
  },

  // beforeRouteLeave(to, from, next) {
  //   if (to.name === "login") {
  //     next();
  //   } else if (!this.onLiveEditing) {
  //     this.preventNav(next);
  //   } else {
  //     next();
  //   }
  // },

  methods: {
    leaveReport() {
      if (this.isOrgAdmin) {
        this.$router.push("/org-dashboard");
      } else {
        //is wg
        this.$router.push("/dashboard");
      }
    },

    async leaveSite() {
      this.showModal = false;
      if (this.nextPage == "refresh") {
        location.reload();
      } else {
        if (
          this.$route.query.cr ||
          this.onLiveEditing ||
          localStorage.getItem("reportOnCourseID")
        ) {
          await ReportService.update({
            id: this.$route.query.cr
              ? this.$route.query.cr
              : localStorage.getItem("reportOnCourseID"),
            data: {
              active_user_id: null
            }
          });
          localStorage.removeItem("reportOnCourseID");
        }
        this.nextPage();
      }
    },

    preventNav(next) {
      if (next.type == "beforeunload") {
        next.preventDefault();
        next.returnValue = "";
        this.nextPage = "refresh";
        this.showModal = true;
      } else {
        this.nextPage = next;
        this.showModal = true;
      }
    },

    recoverDataBackup() {
      this.loadPreviousData = false;
      this.answersBackup = true;
      this.loadQuiz = true;
    },

    wrapIframe(content) {
      content = content.replaceAll(
        "<iframe",
        "<div class='video-container'><iframe"
      );
      content = content.replaceAll("</iframe>", "</iframe></div>");
      return content;
    },

    // async fetchQuizData() {
    //   const quizData = await UserService.getQuiz(
    //     this.currentSectionData.quiz_id
    //   );
    // },

    async getQuizForCurrentSection() {
      this.loadQuiz = false;
      this.answersBackup = false;
      let quizLoaded = false;

      if (this.currentSectionData?.quiz_id) {
        if (
          !this.$route.query.cr &&
          window.localStorage.getItem(
            "backupQuiz_" + this.currentSectionData.quiz_id
          ) !== null &&
          Object.keys(
            JSON.parse(
              window.localStorage.getItem(
                "backupQuiz_" + this.currentSectionData.quiz_id
              )
            )
          ).length > 1
        ) {
          this.loadPreviousData = true;
        } else {
          this.loadPreviousData = false;
          this.loadQuiz = true;
        }

        //check if the has already completed the firs questionare

        const quizData = await UserService.getQuiz(
          this.currentSectionData.quiz_id
        );
        this.quiz = quizData.data.data;

        if (this.$route.query.cr) {
          const responseReport = await ReportService.getExtendedReport({
            id: this.$route.query.cr
          });
          if (
            (JSON.parse(responseReport.data.report.debug_data).continueReport &&
              JSON.parse(responseReport.data.report.debug_data).continueReport
                .savedBackup) ||
            JSON.parse(responseReport.data.report.debug_data).prevCompletedQuiz
          ) {
            // let dataBackup = JSON.parse(responseReport.data.report.debug_data).continueReport.dataBackup;
            // var findQuizBackup = dataBackup.data.findIndex(e => e.quizId === this.quiz.id)
            // if (findQuizBackup >= 0) {//if we have the previous quiz completed, we can continue
            this.crQuizReady = true;
            // }
          }
        }

        quizLoaded = true;
      }
      return (this.isQuizLoaded = quizLoaded);
    },

    applyPaginationUrls() {
      // Add next/prev urls to chapters/pages
      this.course.chapters.forEach((chapter, chapterIndex) => {
        const nextChapter = this.course.chapters[chapterIndex + 1];
        const prevChapter = this.course.chapters[chapterIndex - 1];

        if (chapter.pages) {
          chapter.pages.forEach((page, pageIndex) => {
            const nextPage = chapter.pages[pageIndex + 1];
            const prevPage = chapter.pages[pageIndex - 1];

            if (nextPage) {
              if (this.$route.query.cr) {
                page.nextUrl = `${this.course.id}?chapter=${chapter.id}&page=${nextPage.id}&cr=${this.$route.query.cr}`;
              } else {
                page.nextUrl = `${this.course.id}?chapter=${chapter.id}&page=${nextPage.id}`;
              }
            } else {
              if (nextChapter) {
                if (this.$route.query.cr) {
                  page.nextUrl = `${this.course.id}?chapter=${nextChapter.id}&cr=${this.$route.query.cr}`;
                } else {
                  page.nextUrl = `${this.course.id}?chapter=${nextChapter.id}`;
                }
              }
            }

            if (prevPage) {
              if (this.$route.query.cr) {
                page.prevUrl = `${this.course.id}?chapter=${chapter.id}&page=${prevPage.id}&cr=${this.$route.query.cr}`;
              } else {
                page.prevUrl = `${this.course.id}?chapter=${chapter.id}&page=${prevPage.id}`;
              }
            } else {
              if (this.$route.query.cr) {
                page.prevUrl = `${this.course.id}?chapter=${chapter.id}&cr=${this.$route.query.cr}`;
              } else {
                page.prevUrl = `${this.course.id}?chapter=${chapter.id}`;
              }
            }
          });
        }

        // Chapter urls
        if (chapter.pages.length > 0) {
          const firstPageInChapter = chapter.pages[0]["id"];
          if (this.$route.query.cr) {
            chapter.nextUrl = `${this.course.id}?chapter=${chapter.id}&page=${firstPageInChapter}&cr=${this.$route.query.cr}`;
          } else {
            chapter.nextUrl = `${this.course.id}?chapter=${chapter.id}&page=${firstPageInChapter}`;
          }
        } else {
          if (nextChapter) {
            if (this.$route.query.cr) {
              chapter.nextUrl = `${this.course.id}?chapter=${nextChapter.id}&cr=${this.$route.query.cr}`;
            } else {
              chapter.nextUrl = `${this.course.id}?chapter=${nextChapter.id}`;
            }
          }
        }

        // Chapter urls
        if (chapter.pages) {
          if (prevChapter) {
            if (prevChapter.pages.length > 0) {
              const lastPageInPrevChapter =
                prevChapter.pages[prevChapter.pages.length - 1]["id"];
              if (this.$route.query.cr) {
                chapter.prevUrl = `${this.course.id}?chapter=${prevChapter.id}&page=${lastPageInPrevChapter}&cr=${this.$route.query.cr}`;
              } else {
                chapter.prevUrl = `${this.course.id}?chapter=${prevChapter.id}&page=${lastPageInPrevChapter}`;
              }
            } else {
              if (this.$route.query.cr) {
                chapter.prevUrl = `${this.course.id}?chapter=${prevChapter.id}&cr=${this.$route.query.cr}`;
              } else {
                chapter.prevUrl = `${this.course.id}?chapter=${prevChapter.id}`;
              }
            }
          }
        } else {
          if (prevChapter) {
            if (this.$route.query.cr) {
              chapter.prevUrl = `${this.course.id}?chapter=${prevChapter.id}&cr=${this.$route.query.cr}`;
            } else {
              chapter.prevUrl = `${this.course.id}?chapter=${prevChapter.id}`;
            }
          }
        }
      });
    },

    timeConvert(n) {
      var num = n;
      var hours = num / 60;
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      if (rminutes > 1 && rhours > 1) {
        return rhours + " hours and " + rminutes + " minutes";
      } else if (rminutes > 1 && rhours === 0) {
        return rminutes + " minutes";
      } else {
        return rhours + " hours";
      }
    },

    async validateReport() {
      const responseReport = await ReportService.getExtendedReport({
        id: this.$route.query.cr
      });
      if (
        responseReport.data.report.active_user_id != null &&
        responseReport.data.report.active_user_id != this.currentUser.user.id
      ) {
        this.onLiveEditing = true;
      }
    }
  },
  watch: {
    "$route.query.chapter"() {
      this.currentChapterId = this.$route.query.chapter;
      this.getQuizForCurrentSection();
    },
    "$route.query.page"() {
      this.currentPageId = this.$route.query.page;
      this.getQuizForCurrentSection();
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }

    if (this.$route.query.cr) {
      this.$store.dispatch("quizStatus/clearQuiz");
      this.validateReport();
    }

    UserService.getCourse(this.$route.params.id).then(
      response => {
        (this.isLoaded = true), (this.course = response.data.data);

        this.breadcrumbsItems.push({
          text: this.course.title,
          disabled: true,
          href: ""
        });

        // get the quiz id
        this.customQuizId = this.course.custom_quizzes[0].id;
        //console.log("course quiz id", this.customQuizId);

        // Go to the first chapter automatically
        if (!this.currentChapterId) {
          const firstChapterId = this.course.chapters[0]["id"];
          this.$router.push(`${this.course.id}?chapter=${firstChapterId}`);
        }

        // Get the ID's of all the quizzes in this course
        this.quizzesInCourse = this.course.chapters.reduce((quizzes, item) => {
          if (item.quiz_id) {
            quizzes.push(item.quiz_id);
          }
          if (item.pages) {
            item.pages.forEach(page => {
              if (page.quiz_id) {
                quizzes.push(page.quiz_id);
              }
            });
          }
          return quizzes;
        }, []);

        // Temp, strip out arrays in pages
        this.course.chapters.forEach(chapter => {
          if (chapter.pages) {
            chapter.pages = chapter.pages.filter(page => {
              if (!Array.isArray(page)) {
                return page;
              }
            });
          }
        });

        this.getQuizForCurrentSection();

        this.applyPaginationUrls();
      },
      error => {
        this.apiError =
          (error.response && error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }
};
</script>
<style>
.course-content .width-md {
  max-width: 400px !important;
}

.course-content .width-lg {
  max-width: 650px !important;
}

.course-content .width-lg2 {
  max-width: 800px !important;
}

.course-content .width-xl {
  max-width: 1000px !important;
}

.course-content .formula-lg {
  width: auto !important;
  height: 70px !important;
}

.course-content .formula-md {
  width: auto !important;
  height: 70px !important;
}

.course-content .formula-sm {
  width: auto !important;
  height: 70px !important;
}

.course-content img {
  width: 100%;
  height: auto;
  margin: 0px 20px 20px 0px;
}

.course-content table {
  width: 100%;
}
</style>

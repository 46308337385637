import axios from "axios";
import authHeader from "./auth-header";
import store from "../store";

const API_URL = process.env.VUE_APP_API_URL;

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (
      error.response.status === 401 &&
      JSON.parse(localStorage.getItem("user"))
    ) {
      store.dispatch("auth/logout");
      // window.location = "/login";
    }
    return Promise.reject(error);
  }
);

class UserService {
  getCourses() {
    return axios.get(`${API_URL}/courses`, { headers: authHeader() });
  }

  getCourse(courseId) {
    return axios.get(`${API_URL}/course/${courseId}`, {
      headers: authHeader()
    });
  }
  getQuiz(quizId) {
    return axios.get(`${API_URL}/quiz/${quizId}?tooltips=vue`, {
      headers: authHeader()
    });
  }
}

export default new UserService();

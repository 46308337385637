<template>
  <v-card class="mx-auto rounded-br-xl  " elevation="0">
    <v-img height="132" :src="courseImage"></v-img>
    <v-system-bar color="white" class="px-6 pt-12 pb-6">
      <h5 class="h5">Course Contents</h5>
    </v-system-bar>

    <div class="chapter-navigation text-body-2">
      <ol>
        <li v-for="chapter in chapters" :key="chapter.id" class="chapters">
          <template v-if="chapter.quiz_id">
            <router-link
              :to="
                `?chapter=${chapter.id}${
                  continueReport ? '&cr=' + continueReport : ''
                }`
              "
              >{{ chapter.title }} <v-icon>mdi-clipboard-list</v-icon>
            </router-link>
          </template>
          <template v-else>
            <router-link
              :to="
                `?chapter=${chapter.id}${
                  continueReport ? '&cr=' + continueReport : ''
                }`
              "
              >{{ chapter.title }}</router-link
            >
          </template>

          <ol class="chapter-navigation--secondary">
            <li v-for="page in chapter.pages" :key="page.id">
              <template v-if="page.quiz_id">
                <router-link
                  :to="
                    `?chapter=${chapter.id}&page=${page.id}${
                      continueReport ? '&cr=' + continueReport : ''
                    }`
                  "
                  >{{ page.title }} <v-icon>mdi-clipboard-list</v-icon>
                </router-link>
              </template>
              <template v-else>
                <router-link
                  :to="
                    `?chapter=${chapter.id}&page=${page.id}${
                      continueReport ? '&cr=' + continueReport : ''
                    }`
                  "
                  >{{ page.title }}</router-link
                >
              </template>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "ChapterNavigation",
  props: {
    currentCourse: Number,
    courseImage: null,
    chapters: Array,
    continueReport: String
  }
};
</script>

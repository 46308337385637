<template>
  <div class="quiz">
    <h3 class="text-h5 mb-5">{{ quiz.name }}</h3>

    <v-card class="mx-auto" elevation="8">
      <v-list-item class="pa-0">
        <v-list-item-content v-if="!loaderQuiz" class="pa-0">
          <div v-if="quizIntro">
            <div
              class="d-flex align-center justify-space-between progress rounded-t pa-5"
            >
              <div v-html="quiz.started_heading"></div>
            </div>
            <div class="pa-5">
              <!-- If this is the first quiz and/or there is data in this.$store.state.quizStatus.completed for the quizzes before this one, show quiz.startedHtml and quiz.startButtonText button -->

              <template v-if="quizReady === true && quizIsCompleted === false">
                <!-- <template v-if="quizReady === true && questionList > 0"> -->
                <div v-html="quiz.started_html"></div>
                <div class="d-sm-flex align-center">
                  <v-btn
                    :disabled="validateStartBtn"
                    color="primary"
                    rounded
                    @click="startQuiz"
                  >
                    {{ quiz.start_button_text }}
                  </v-btn>

                  <v-select
                    v-if="this.firstQuiz && workerGroupsList.length > 1"
                    v-model="workerGroupSelected"
                    :items="workerGroupsList"
                    item-text="name"
                    :menu-props="{ maxHeight: '400' }"
                    label="Select a Worker Group"
                    return-object
                    class="ml-sm-3"
                    style="max-width: 320px"
                  ></v-select>
                </div>
              </template>

              <!-- Else if quiz can be found in vuex store - show summary and reset assessment which clears vuex store and starts quiz (new reportid if 1st quiz) -->
              <template v-else-if="quizIsCompleted === true">
                <div v-html="quiz.completed_html"></div>
                <v-divider class="mb-5"></v-divider>

                <!-- If the final quiz, show the report buttons -->
                <template v-if="finalQuiz === true">
                  <v-alert
                    border="top"
                    colored-border
                    type="info"
                    elevation="2"
                  >
                    Report #{{ storedReport }} has been submitted.
                    <template v-if="isOrgAdmin">
                      Go to
                      <router-link to="/org-dashboard">Dashboard</router-link
                      >&nbsp;to preview, download and review the full report.
                    </template>

                    <template v-else>
                      Go to
                      <router-link to="/dashboard">Dashboard</router-link
                      >&nbsp;to preview and download the full report.
                    </template>
                  </v-alert>

                  <div
                    class="d-md-flex mb-5 align-center justify-space-between"
                  >
                    <div>
                      <h3 class="text-h5">
                        Final Risk Assessment:
                        <span
                          :class="`${finalRiskAssessment.alert_type}--text`"
                        >
                          <strong>{{
                            finalRiskAssessment.profile_name
                          }}</strong>
                        </span>
                      </h3>
                      <h3 class="text-h5">
                        Final Risk Score:
                        <span
                          :class="`${finalRiskAssessment.alert_type}--text`"
                        >
                          <strong>{{ finalRiskAssessmentScore }}</strong>
                        </span>
                      </h3>
                    </div>
                  </div>

                  <v-simple-table
                    fixed-header
                    dense
                    loading
                    loading-text="Loading... Please wait"
                    class="mb-8"
                  >
                    <thead>
                      <tr>
                        <th class="text-left grey lighten-3">
                          Assessment Name
                        </th>
                        <th class="text-left grey lighten-3">
                          Risk Assessment
                        </th>
                        <th class="text-left grey lighten-3">Risk Score</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(qz, index) in myQuizzes"
                        :key="`${index}-${qz.id}`"
                      >
                        <td>{{ qz.quizName }}</td>
                        <td>
                          <!-- <span
                        v-if="index === 0"
                        :class="`${qz.quizRisk.alert_type}--text`"
                        >-->
                          <template v-if="index === 0">{{
                            qz.quizRisk.profile_name
                          }}</template>
                        </td>
                        <td>
                          <!-- <span :class="`${qz.quizRisk.alert_type}--text`"> -->
                          {{ qz.quizRiskTotal }}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <th class="text-left grey lighten-3">
                          Final Risk Assessment
                        </th>
                        <th class="text-left grey lighten-3">
                          <span
                            :class="`${finalRiskAssessment.alert_type}--text`"
                          >
                            <strong>
                              {{ finalRiskAssessment.profile_name }}
                            </strong>
                          </span>
                        </th>
                        <th class="text-left grey lighten-3">
                          <span
                            :class="`${finalRiskAssessment.alert_type}--text`"
                          >
                            <strong>{{ finalRiskAssessmentScore }}</strong>
                          </span>
                        </th>
                      </tr>
                    </tfoot>
                  </v-simple-table>
                </template>

                <template v-else>
                  <div
                    class="d-md-flex mb-5 align-center justify-space-between"
                  >
                    <!-- not the last quiz in course -->
                    <div
                      v-for="(qz, index) in myQuizzes"
                      :key="`${index}-${qz.id}`"
                    >
                      <template v-if="index === 0">
                        <h3 class="text-h5">
                          Risk Assessment:
                          <span :class="`${qz.quizRisk.alert_type}--text`">
                            <strong>{{ qz.quizRisk.profile_name }}</strong>
                          </span>
                        </h3>
                        <h3 class="text-h5">
                          Risk Score:
                          <span :class="`${qz.quizRisk.alert_type}--text`">
                            <strong>{{ qz.quizRiskTotal }}</strong>
                          </span>
                        </h3>
                      </template>
                    </div>
                  </div>
                </template>

                <v-btn color="primary" rounded @click="retakeQuiz">
                  <v-icon left>mdi-refresh</v-icon>
                  Retake this assessment
                </v-btn>
              </template>

              <!-- Else show a warning message and hide start button -->
              <template v-else>
                <v-alert type="error" elevation="5" dense>
                  Access Restricted. You need to complete other assessments
                  before proceeding.
                </v-alert>
                <div v-html="quiz.started_html"></div>
                <v-btn color="primary" disabled rounded>{{
                  quiz.start_button_text
                }}</v-btn>
              </template>
            </div>
          </div>

          <div v-if="quizQuestions">
            <div
              class="d-flex align-center justify-space-between progress rounded-t pa-5"
            >
              <div class="progress__percent">
                Progress: {{ quizProgressPercentage }}%
              </div>
              <div class="progress__bar">
                <v-progress-linear
                  :value="quizProgressPercentage"
                  rounded
                  dark
                  color="white"
                ></v-progress-linear>
              </div>
              <div>{{ currentQuestionIndex + 1 }} of {{ quizLength }}</div>
            </div>
            <template v-if="questionList">
              <div class="question pa-5">
                <v-form ref="form" v-model="valid" v-on:submit.prevent>
                  <!-- current question label/text -->
                  <div class="d-flex align-center mb-3">
                    <h3 class="text-h5">
                      <computedQuestion
                        :codeProps="currentQuestion.content_special"
                      />
                    </h3>
                    <div v-if="currentQuestion.help_text">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            class="pl-2"
                          >
                            mdi-alert-circle
                          </v-icon>
                        </template>
                        <span>{{ currentQuestion.help_text }}</span>
                      </v-tooltip>
                    </div>
                  </div>

                  <!-- if question type is a text field -->
                  <div v-if="currentQuestion.type === 'section'"></div>

                  <div v-if="currentQuestion.type === 'text'">
                    <v-text-field
                      v-model="answers[currentQuestion.id]"
                      :rules="[v => !!v || 'Answer is required']"
                      label="Please enter"
                      filled
                      :required="currentQuestion.required"
                    ></v-text-field>
                  </div>

                  <!-- if question type is a textarea -->
                  <div v-if="currentQuestion.type === 'textarea'">
                    <v-textarea
                      v-model="answers[currentQuestion.id]"
                      :rules="[v => !!v || 'Answer is required']"
                      label="Please enter"
                      value="Please enter"
                      filled
                      :required="currentQuestion.required"
                    ></v-textarea>
                  </div>

                  <!-- if question type is radio buttons -->
                  <div v-if="currentQuestion.type === 'radio'">
                    <v-radio-group
                      v-model="answers[currentQuestion.id]"
                      column
                      :rules="[v => !!v || 'Answer is required']"
                    >
                      <div
                        v-for="item in currentQuestion.responses"
                        :key="item.id"
                        class="mb-2"
                      >
                        <div class="mb-2 d-flex align-center">
                          <v-radio
                            :value="item.id"
                            color="secondary"
                            :required="currentQuestion.required"
                          >
                            <template v-slot:label>
                              <span v-html="item.content"></span>
                            </template>
                          </v-radio>
                          <div class="mb-2 ml-1" v-if="item.help_text">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-alert-circle
                                </v-icon>
                              </template>
                              <span>{{ item.help_text }}</span>
                            </v-tooltip>
                          </div>
                        </div>
                      </div>
                    </v-radio-group>
                  </div>

                  <!-- if question type is checkboxes -->
                  <div v-if="currentQuestion.type === 'checkbox'">
                    <div v-for="item in sortedResponses" :key="item.id">
                      <div class="d-flex align-center">
                        <v-checkbox
                          ref="chex"
                          v-model="answers[currentQuestion.id]"
                          :multiple="true"
                          :value="item.id"
                          :rules="checkboxRules"
                          @change="
                            validateAnswer(
                              item,
                              currentQuestion.id,
                              currentQuestion.responses
                            )
                          "
                          color="secondary"
                          hide-details
                          :required="currentQuestion.required"
                        >
                          <template v-slot:label>
                            <span v-html="item.content"></span>
                          </template>
                        </v-checkbox>

                        <v-tooltip v-if="item.help_text" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="primary"
                              dark
                              v-bind="attrs"
                              v-on="on"
                              class="pt-4 pl-2"
                            >
                              mdi-alert-circle
                            </v-icon>
                          </template>
                          <span>{{ item.help_text }}</span>
                        </v-tooltip>
                      </div>
                    </div>
                  </div>

                  <!-- if question type is a dropdown select -->
                  <div v-if="currentQuestion.type === 'select'">
                    <v-select
                      v-model="answers[currentQuestion.id]"
                      :items="formatSelectOptions(currentQuestion.responses)"
                      label="Please select"
                      :rules="[v => !!v || 'Answer is required']"
                      filled
                      :required="currentQuestion.required"
                    ></v-select>
                  </div>
                </v-form>
              </div>

              <!-- Quiz Controls -->
              <div class="d-flex pa-5">
                <v-btn
                  color="secondary"
                  rounded
                  v-if="currentQuestionIndex > 0"
                  v-on:click="previousHandler"
                  >Previous
                </v-btn>
                <v-btn
                  color="secondary"
                  rounded
                  v-on:click="nextHandler"
                  class="ml-auto"
                  :disabled="!valid"
                  >Next
                </v-btn>
              </div>
            </template>
          </div>

          <div v-if="quizResults" id="printSummary">
            <div
              class="d-flex align-center justify-space-between progress rounded-t pa-5"
            >
              <div v-html="quiz.completed_heading"></div>
            </div>

            <div class="pa-5">
              <div v-html="quiz.completed_html"></div>

              <v-divider class="mb-5"></v-divider>

              <!-- If quiz summary -->
              <div class="d-md-flex mb-5 align-center justify-space-between">
                <!-- If the final quiz, show the download button -->
                <template v-if="finalQuiz === true">
                  <div>
                    <h3 class="text-h5">
                      Final Risk Assessment:
                      <span :class="`${finalRiskAssessment.alert_type}--text`">
                        <strong>{{ finalRiskAssessment.profile_name }}</strong>
                      </span>
                    </h3>
                    <h3 class="text-h5">
                      Final Risk Score:
                      <span :class="`${finalRiskAssessment.alert_type}--text`">
                        <strong>{{ finalRiskAssessmentScore }}</strong>
                      </span>
                    </h3>
                  </div>
                  <div>
                    <v-btn
                      :href="submittedReport.html"
                      target="_blank"
                      color="black"
                      rounded
                      dark
                      class="ml-auto mr-3 my-1"
                    >
                      <v-icon left>mdi-open-in-new</v-icon>
                      View Full Report
                    </v-btn>

                    <v-btn
                      :href="submittedReport.pdf"
                      target="_blank"
                      color="secondary"
                      rounded
                      class="ml-auto mr-3 my-1"
                    >
                      <v-icon left>mdi-file-document-outline</v-icon>
                      Download PDF Report
                    </v-btn>

                    <!-- <v-btn text small rounded class="ml-auto my-1" @click="print">Print Summary</v-btn> -->
                  </div>
                </template>
                <template v-else>
                  <div>
                    <h3 class="text-h5">
                      Risk Assessment:
                      <span :class="`${riskResult.alert_type}--text`">
                        <strong>{{ riskResult.profile_name }}</strong>
                      </span>
                    </h3>
                    <h3 class="text-h5">
                      Risk Score:
                      <span :class="`${riskResult.alert_type}--text`">
                        <strong>{{ totalScore }}</strong>
                      </span>
                    </h3>
                  </div>
                </template>
              </div>

              <!-- If the final quiz show all the quiz summary tables if more than 1 quiz -->
              <template v-if="finalQuiz === true">
                <v-simple-table
                  fixed-header
                  dense
                  loading
                  loading-text="Loading... Please wait"
                  class="mb-8"
                >
                  <thead>
                    <tr>
                      <th class="text-left grey lighten-3">Assessment Name</th>
                      <th class="text-left grey lighten-3">Risk Assessment</th>
                      <th class="text-left grey lighten-3">Risk Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(qz, index) in myQuizzes"
                      :key="`${index}-${qz.id}`"
                    >
                      <td>{{ qz.quizName }}</td>
                      <td>
                        <!-- <span
                        v-if="index === 0"
                        :class="`${qz.quizRisk.alert_type}--text`"
                      >-->
                        <template v-if="index === 0">{{
                          qz.quizRisk.profile_name
                        }}</template>
                      </td>
                      <td>
                        <!-- <span :class="`${qz.quizRisk.alert_type}--text`"> -->
                        {{ qz.quizRiskTotal }}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th class="text-left grey lighten-3">
                        Final Risk Assessment
                      </th>
                      <th class="text-left grey lighten-3">
                        <span
                          :class="`${finalRiskAssessment.alert_type}--text`"
                        >
                          <strong>
                            {{ finalRiskAssessment.profile_name }}
                          </strong>
                        </span>
                      </th>
                      <th class="text-left grey lighten-3">
                        <span
                          :class="`${finalRiskAssessment.alert_type}--text`"
                        >
                          <strong>{{ finalRiskAssessmentScore }}</strong>
                        </span>
                      </th>
                    </tr>
                  </tfoot>
                </v-simple-table>
              </template>

              <!-- Quiz breakdown of question, answers and score -->
              <h3 class="text-h5 mb-3">{{ quiz.name }} Breakdown</h3>

              <v-simple-table
                fixed-header
                dense
                loading
                loading-text="Loading... Please wait"
                class="results-table"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left grey lighten-3">Question</th>
                      <th class="text-left grey lighten-3">Response</th>
                      <th class="text-left grey lighten-3">Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="answer in newAnswers" :key="answer.question_id">
                      <td>
                        <span v-html="answer.content_plain"></span>
                      </td>
                      <td>
                        <p
                          v-for="response in answer.responses"
                          v-bind:key="response.id"
                        >
                          {{ response.response }}
                        </p>
                      </td>
                      <td>
                        <span
                          v-for="score in answer.responses"
                          v-bind:key="score.risk_score"
                        >
                          <p v-if="score.risk_score > 0">
                            {{ score.risk_score }}
                          </p>
                        </span>
                      </td>
                    </tr>
                  </tbody>

                  <tfoot>
                    <tr>
                      <th class="text-left grey lighten-3" colspan="2">
                        Total
                      </th>
                      <th class="text-left grey lighten-3">
                        <!-- <span :class="`${riskResult.alert_type}--text`"> -->
                        <span class="black--text">
                          <strong>{{ totalScore }}</strong>
                        </span>
                      </th>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </div>
          </div>
        </v-list-item-content>

        <div class="d-flex align-center justify-center pa-10 ma-auto" v-else>
          <v-progress-circular
            indeterminate
            color="blue-grey"
          ></v-progress-circular>
        </div>
      </v-list-item>
    </v-card>

    <v-alert
      v-if="currentQuestion && currentQuestion.help_html && quizIntro == false"
      border="left"
      color="#34a6c6"
      type="info"
      icon="mdi-alert-circle"
      class="mt-5 box-information"
      style="font-size: 14px"
    >
      <div v-html="currentQuestion && currentQuestion.help_html"></div>
    </v-alert>

    <v-snackbar v-model="snackbar" right top>
      {{ alert }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>

    <v-dialog v-model="showModal" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">
          Are you sure to leave?
        </v-card-title>
        <v-card-text
          >If you leave, all your progress will be saved and can be loaded
          later.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="leaveSite()">
            Yes
          </v-btn>
          <v-btn color="red darken-1" text @click="showModal = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import ReportService from "@/services/report.service";
import ResultsService from "@/services/results.service";
import TeamWorkergroupService from "@/services/teamworkergroup.service";
import authServices from "@/services/auth.service";

import { Role } from "@/helpers/roles";

export default {
  name: "Quiz",
  data() {
    return {
      firstQuizCompleted: false,
      showModal: false,
      nextPage: null,
      snackbar: false,
      alert: "",
      valid: true,
      quizIntro: true,
      quizQuestions: false,
      quizResults: false,
      currentQuestionIndex: 0,
      answers: {},
      newAnswers: [],
      results: [],
      cleanResults: [],
      scores: {},
      totalScore: 0,
      riskResult: [],
      finalRiskAssessment: [],
      required: null,
      questionList: [],
      reportId: "",
      lastReportId: "",
      submittedReport: [],
      finalRiskAssessmentScore: 0,
      workerGroupSelected: null,
      workerGroupsList: [],
      loaderQuiz: false,
      crData: null
    };
  },

  computed: {
    validateStartBtn: function() {
      if (this.workerGroupSelected == null && this.firstQuiz == true) {
        return true;
      } else {
        return false;
      }
    },

    sortedResponses() {
      // sort by sort_order
      const sortedResponses = this.currentQuestion.responses.slice();
      return _.orderBy(sortedResponses, "sort_order", "asc");
    },

    currentUser: function() {
      return this.$store.state.auth.user;
    },
    isOrgAdmin() {
      let orgAdmin = false;
      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.OrgAdmin) {
          orgAdmin = true;
        }
      });
      return orgAdmin;
    },
    currentQuestion: function() {
      return this.questionList[this.currentQuestionIndex];
    },
    quizLength: function() {
      return this.questionList.length;
    },
    quizProgressPercentage: function() {
      return Math.round((this.currentQuestionIndex * 100) / this.quizLength);
    },
    initialQuestionList: function() {
      // sort by sort_order
      const sortedQuestions = this.quiz.questions.slice();
      return _.orderBy(sortedQuestions, "sort_order", "asc");
    },
    myQuizzes: function() {
      if (this.$route.query.cr) {
        return this.$store.state.quizStatus.myQuizzes.slice(0).reverse();
      } else {
        return this.$store.state.quizStatus.myQuizzes;
      }
    },
    checkboxRules: function() {
      return [
        (this.answers[this.currentQuestion.id] &&
          this.answers[this.currentQuestion.id].length > 0) ||
          "You must select at least one"
      ];
    }
  },
  components: {
    computedQuestion: () => import("@/components/TooltipQuizz.vue")
  },
  props: {
    course: {},
    quiz: {
      type: Object,
      default: () => {}
    },
    firstQuiz: Boolean,
    finalQuiz: Boolean,
    quizReady: Boolean,
    storedReport: Number,
    quizIsCompleted: Boolean,
    answersBackup: Boolean
  },

  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },

  mounted() {
    this.initialize();
  },

  methods: {
    validateAnswer(item, questionID, cqResponses) {
      if (item.content == "None") {
        //if a "None" response is selected, we clean all the other responses
        this.answers[questionID].length = 0;
        this.answers[questionID].push(item.id);
      } else {
        let noneIndex = cqResponses.findIndex(e => e.content == "None");
        if (noneIndex >= 0) {
          //if a "None" response exist as a possible response of the question
          if (
            this.answers[questionID].findIndex(
              e => e == cqResponses[noneIndex].id
            ) >= 0
          ) {
            //if a "None" response already selected, we delete it
            this.answers[questionID].splice(
              this.answers[questionID].findIndex(
                e => e == cqResponses[noneIndex].id
              ),
              1
            );
          }
        }
      }
    },

    async leaveSite() {
      this.showModal = false;
      if (this.nextPage == "refresh") {
        location.reload();
      } else {
        await ReportService.update({
          id: this.reportId ? this.reportId : this.$route.query.cr,
          data: {
            active_user_id: null
          }
        });

        this.nextPage();
      }
    },

    preventNav(next) {
      if (next.type == "beforeunload") {
        next.preventDefault();
        next.returnValue = "";
        this.nextPage = "refresh";
        this.showModal = true;
      } else {
        this.nextPage = next;
        this.showModal = true;
      }
    },

    showSnackbar(text) {
      this.alert = text;
      this.snackbar = true;
    },

    clearBackup() {
      window.localStorage.removeItem("backupQuiz_" + this.quiz.id);
    },

    saveBackup() {
      let backupQuiz = {
        answers: this.answers,
        currentQuestionIndex: this.currentQuestionIndex,
        newAnswers: this.newAnswers,
        quizIntro: this.quizIntro,
        quizQuestions: this.quizQuestions,
        questionList: this.questionList,
        workerGroupSelected: this.workerGroupSelected,
        firstQuizCompleted: this.firstQuizCompleted,
        scores: this.scores,
        reportId: this.reportId
      };
      window.localStorage.setItem(
        "backupQuiz_" + this.quiz.id,
        JSON.stringify(backupQuiz)
      );
    },

    print() {
      // Pass the element id here
      this.$htmlToPaper("printSummary");
    },

    async initialize() {
      //revisar si ya estan todas las answers, esta quiz ya esta completada
      if (this.$route.query.cr) {
        //if the user come from dashboard to continue a report

        this.loaderQuiz = true;

        await ReportService.update({
          id: this.$route.query.cr,
          data: {
            active_user_id: this.currentUser.user.id
          }
        });

        const responseReport = await ReportService.getExtendedReport({
          id: this.$route.query.cr
        });

        this.crData = responseReport;
        if (JSON.parse(responseReport.data.report.debug_data).continueReport) {
          if (
            JSON.parse(responseReport.data.report.debug_data).continueReport
              .savedBackup
          ) {
            let quizSaved = Object.keys(
              JSON.parse(responseReport.data.report.debug_data).continueReport
                .savedBackup
            );
            let dataBackup = JSON.parse(responseReport.data.report.debug_data)
              .continueReport.savedBackup;
            let indexQuizSaved = quizSaved.findIndex(e => e == this.quiz.id);
            if (indexQuizSaved >= 0) {
              //the quiz is equal at our save
              this.answers = dataBackup[this.quiz.id].answers;
              this.currentQuestionIndex =
                dataBackup[this.quiz.id].currentQuestionIndex;
              this.newAnswers = dataBackup[this.quiz.id].newAnswers;
              this.quizIntro = dataBackup[this.quiz.id].quizIntro;
              this.quizQuestions = dataBackup[this.quiz.id].quizQuestions;
              this.questionList = dataBackup[this.quiz.id].questionList;
              this.quizIsCompleted = false;
              this.workerGroupSelected =
                dataBackup[this.quiz.id].workerGroupSelected;
              this.scores = dataBackup[this.quiz.id].scores;
              this.reportId = dataBackup[this.quiz.id].reportId;
              //if it already has all the answers
              if (
                Object.values(dataBackup[this.quiz.id].answers).length ===
                dataBackup[this.quiz.id].questionList.length
              ) {
                this.quizCompleted();
              }
            }
          }

          let dataBackup = JSON.parse(responseReport.data.report.debug_data)
            .continueReport.dataBackup;
          let findQuizBackup = dataBackup.data.findIndex(
            e => e.quizId === this.quiz.id
          );
          if (
            findQuizBackup >= 0 &&
            dataBackup.data[findQuizBackup].quizId == this.quiz.id
          ) {
            //the quiz is equal at our save
            this.answers = dataBackup.data[findQuizBackup].answers;
            this.currentQuestionIndex =
              dataBackup.data[findQuizBackup].currentQuestionIndex;
            this.newAnswers = dataBackup.data[findQuizBackup].newAnswers;
            this.quizIntro = dataBackup.data[findQuizBackup].quizIntro;
            this.quizQuestions = dataBackup.data[findQuizBackup].quizQuestions;
            this.questionList = dataBackup.data[findQuizBackup].questionList;
            this.quizIsCompleted = false;
            this.workerGroupSelected =
              dataBackup.data[findQuizBackup].workerGroupSelected;
            this.scores = dataBackup.data[findQuizBackup].scores;
            this.reportId = dataBackup.data[findQuizBackup].reportId;
            //if it already has all the answers
            if (
              Object.values(dataBackup.data[findQuizBackup].answers).length ===
              dataBackup.data[findQuizBackup].questionList.length
            ) {
              this.quizCompleted();
            }
          } else {
            this.updateQuestionList();
          }
        } else {
          let quizSaved = Object.keys(
            JSON.parse(responseReport.data.report.debug_data).prevCompletedQuiz
          );
          let dataBackup = JSON.parse(responseReport.data.report.debug_data)
            .prevCompletedQuiz;
          let indexQuizSaved = quizSaved.findIndex(e => e == this.quiz.id);
          if (indexQuizSaved >= 0) {
            //the quiz is equal at our save
            this.answers = dataBackup[this.quiz.id].answers;
            this.currentQuestionIndex =
              dataBackup[this.quiz.id].currentQuestionIndex;
            this.newAnswers = dataBackup[this.quiz.id].newAnswers;
            this.quizIntro = dataBackup[this.quiz.id].quizIntro;
            this.quizQuestions = dataBackup[this.quiz.id].quizQuestions;
            this.questionList = dataBackup[this.quiz.id].questionList;
            this.quizIsCompleted = false;
            this.workerGroupSelected =
              dataBackup[this.quiz.id].workerGroupSelected;
            this.scores = dataBackup[this.quiz.id].scores;
            this.reportId = dataBackup[this.quiz.id].reportId;
            //if it already has all the answers
            if (
              Object.values(dataBackup[this.quiz.id].answers).length ===
              dataBackup[this.quiz.id].questionList.length
            ) {
              this.quizCompleted();
            }
          }
          this.updateQuestionList();
        }

        this.loaderQuiz = false;
      } else {
        this.loaderQuiz = true;

        this.reportId = this.storedReport;
        if (this.reportId !== null) {
          const responseReport = await ReportService.getExtendedReport({
            id: this.reportId ? this.reportId : this.$route.query.cr
          });

          this.crData = responseReport;

          if (
            JSON.parse(responseReport.data.report.debug_data).continueReport &&
            JSON.parse(responseReport.data.report.debug_data).continueReport
              .savedBackup
          ) {
            let quizSaved = Object.keys(
              JSON.parse(responseReport.data.report.debug_data).continueReport
                .savedBackup
            );
            let dataBackup = JSON.parse(responseReport.data.report.debug_data)
              .continueReport.savedBackup;
            let indexQuizSaved = quizSaved.findIndex(e => e == this.quiz.id);
            if (indexQuizSaved >= 0) {
              //the quiz is equal at our save
              this.answers = dataBackup[this.quiz.id].answers;
              this.currentQuestionIndex =
                dataBackup[this.quiz.id].currentQuestionIndex;
              this.newAnswers = dataBackup[this.quiz.id].newAnswers;
              this.quizIntro = dataBackup[this.quiz.id].quizIntro;
              this.quizQuestions = dataBackup[this.quiz.id].quizQuestions;
              this.questionList = dataBackup[this.quiz.id].questionList;
              this.quizIsCompleted = false;
              this.workerGroupSelected =
                dataBackup[this.quiz.id].workerGroupSelected;
              this.scores = dataBackup[this.quiz.id].scores;
              this.reportId = dataBackup[this.quiz.id].reportId;
              //if it already has all the answers
              if (
                Object.values(dataBackup[this.quiz.id].answers).length ===
                dataBackup[this.quiz.id].questionList.length
              ) {
                this.quizCompleted();
              }
            }
          }
        }

        if (this.myQuizzes.length) {
          this.$store.commit("changeFirstQuizStatus", true);
        }

        const userWorkergroup = await TeamWorkergroupService.get({
          user_id: this.$store.state.auth.user.user.id
        });
        this.workerGroupsList = userWorkergroup.data.slice();

        if (this.workerGroupsList.length == 1) {
          this.workerGroupSelected = this.workerGroupsList[0];
        }

        if (this.answersBackup) {
          let savedBackup = JSON.parse(
            window.localStorage.getItem("backupQuiz_" + this.quiz.id)
          );
          this.answers = savedBackup.answers;
          this.currentQuestionIndex = savedBackup.currentQuestionIndex;
          this.newAnswers = savedBackup.newAnswers;
          this.quizIntro = savedBackup.quizIntro;
          this.quizQuestions = savedBackup.quizQuestions;
          this.questionList = savedBackup.questionList;
          this.quizIsCompleted = false;
          this.workerGroupSelected = savedBackup.workerGroupSelected;
          this.scores = savedBackup.scores;
          this.reportId = savedBackup.reportId;
        }

        if (
          this.finalQuiz &&
          this.myQuizzes[0] &&
          this.myQuizzes[0].quizRiskTotal
        ) {
          // calculate final score
          this.calcFinalRiskAssessment();
        }

        this.updateQuestionList();
        this.loaderQuiz = false;
      }
    },

    retakeQuiz() {
      this.$store.commit("changeFirstQuizStatus", false);
      this.quizIsCompleted = false;
      this.quizIntro = true;
      this.quizQuestions = false;
    },

    //Start Quiz Questions Screen
    async startQuiz() {
      //  on first quiz in courses - POST to the report API with all the below data to generate a report_id
      if (this.firstQuiz) {
        //create the report
        const reportData = {
          user_id: this.currentUser.user.id,
          organization_id: this.currentUser.user.organizations.id,
          organization_name: this.currentUser.user.organizations.company,
          quiz_id: this.quiz.id,
          quiz_name: this.quiz.name,
          course_id: this.course.id,
          location_id: this.currentUser.user.locations.id,
          location_name: this.currentUser.user.locations.name,
          workergroup_id: this.workerGroupSelected.id,
          workergroup_name: this.workerGroupSelected.name
        };
        const response = await ReportService.createReport(reportData);
        this.reportId = response.data.report_id;
        const initialStatus = {
          status: "Incomplete"
        };
        await ReportService.update({
          id: this.reportId,
          data: initialStatus
        });

        localStorage.setItem("reportOnCourseID", JSON.stringify(this.reportId));
      }
      this.quizIntro = false;
      this.quizQuestions = true;
    },

    updateQuestionList() {
      this.questionList = this.initialQuestionList.filter(question => {
        if (question.conditions.length > 0) {
          // If the question is conditional, see if we can find it's parent questions in existing answers
          // Answers from current quiz

          //i need to loop through each condition and get the condition_question_id - check if thats been answered in this.answers

          const conditionsMet = question.conditions.map(condition => {
            const answer = this.answers[condition.condition_question_id];
            const requiredAnswer = condition.condition_response_id;

            // Find conditional answers in the current quiz
            if (Array.isArray(answer)) {
              // Handle Array values, e.g. checkboxes / radio buttons
              if (answer.includes(requiredAnswer)) {
                return true;
              }
            } else {
              // Handle String values
              if (answer == requiredAnswer) {
                return true;
              }
            }

            if (
              this.$route.query.cr &&
              JSON.parse(this.crData.data.report.debug_data).prevCompletedQuiz
            ) {
              const savedCompletedQuizzesPrev = JSON.parse(
                this.crData.data.report.debug_data
              ).prevCompletedQuiz;
              if (!this.firstQuiz) {
                if (!savedCompletedQuizzesPrev[condition.condition_quiz_id]) {
                  return false;
                }

                const storedAnswer = savedCompletedQuizzesPrev[
                  condition.condition_quiz_id
                ].newAnswers.filter(
                  item => item.question_id === condition.condition_question_id
                )[0];

                if (storedAnswer) {
                  const storedResponses = storedAnswer.responses.filter(
                    response => response.id === requiredAnswer
                  );

                  // Find conditional answers from already completed quizzes stored in vuex
                  if (savedCompletedQuizzesPrev[condition.condition_quiz_id]) {
                    if (storedResponses.length > 0) {
                      return true;
                    }
                  }
                } else {
                  return false;
                }
              }
            } else if (
              this.$route.query.cr &&
              JSON.parse(this.crData.data.report.debug_data).continueReport &&
              JSON.parse(this.crData.data.report.debug_data).continueReport
                .savedBackup
            ) {
              const savedCompletedQuizzesCont = JSON.parse(
                this.crData.data.report.debug_data
              ).continueReport.savedBackup;
              if (!this.firstQuiz) {
                if (!savedCompletedQuizzesCont[condition.condition_quiz_id]) {
                  return false;
                }
                const storedAnswer = savedCompletedQuizzesCont[
                  condition.condition_quiz_id
                ].newAnswers.filter(
                  item => item.question_id === condition.condition_question_id
                )[0];

                if (storedAnswer) {
                  const storedResponses = storedAnswer.responses.filter(
                    response => response.id === requiredAnswer
                  );

                  // Find conditional answers from already completed quizzes stored in vuex
                  if (savedCompletedQuizzesCont[condition.condition_quiz_id]) {
                    if (storedResponses.length > 0) {
                      return true;
                    }
                  }
                } else {
                  return false;
                }
              }
            } else {
              const localCompletedQuizzes = this.$store.state.quizStatus
                .completed;

              if (!this.firstQuiz) {
                if (!localCompletedQuizzes[condition.condition_quiz_id]) {
                  return false;
                }

                const storedAnswer = localCompletedQuizzes[
                  condition.condition_quiz_id
                ].filter(
                  item => item.question_id === condition.condition_question_id
                )[0];

                if (storedAnswer) {
                  const storedResponses = storedAnswer.responses.filter(
                    response => response.id === requiredAnswer
                  );

                  // Find conditional answers from already completed quizzes stored in vuex
                  if (localCompletedQuizzes[condition.condition_quiz_id]) {
                    if (storedResponses.length > 0) {
                      return true;
                    }
                  }
                } else {
                  return false;
                }
              }
            }

            return false;
          });

          if (conditionsMet.includes(true)) {
            return question;
          }
        } else {
          return question;
        }
      });
    },

    //Update Total Score
    updateTotalScore() {
      this.totalScore = Object.values(this.scores).reduce((acc, value) => {
        return acc + value;
      }, 0);
    },

    //Calculate Final risk assessment score
    calcFinalRiskAssessment() {
      if (
        this.$route.query.cr &&
        JSON.parse(this.crData.data.report.debug_data).continueReport &&
        JSON.parse(this.crData.data.report.debug_data).continueReport
          .savedBackup
      ) {
        this.finalRiskAssessmentScore = Object.values(
          JSON.parse(this.crData.data.report.debug_data).continueReport
            .savedBackup
        ).reduce((acc, value) => {
          return acc + value.totalScore;
        }, 0);
        this.finalRiskAssessmentScore += this.totalScore;
      } else {
        this.finalRiskAssessmentScore = this.myQuizzes.reduce((acc, value) => {
          return acc + value.quizRiskTotal;
        }, 0);
      }

      const riskLevelFinal = this.quiz.risk_levels?.filter(item => {
        return (
          this.finalRiskAssessmentScore >= item.min_score &&
          this.finalRiskAssessmentScore < item.max_score
        );
      });

      //last fix - risklevel calculation
      //final step
      if (riskLevelFinal.length === 0) {
        const defaultRiskResult = [
          { profile_name: "Unknown", alert_type: "info" }
        ];
        this.finalRiskAssessment = defaultRiskResult[0];
      } else {
        this.finalRiskAssessment = riskLevelFinal[0];
      }
    },

    //Next Button
    nextHandler: function() {
      // async nextHandler() {

      //Keep Alive on every next question
      this.$store.commit(
        "updateKeepAliveTimer",
        this.$store.state.keepAliveTimerDefault
      );
      authServices.keepAlive();

      const isValid = this.$refs.form.validate();
      this.loaderQuiz = true;

      if (isValid) {
        //Update the score
        const currentQuestion = this.currentQuestion;
        let ansBlueprint = {
          quiz_id: this.quiz.id,
          question_id: currentQuestion.id,
          responses: [],
          ...currentQuestion
        };

        // clean up question
        delete ansBlueprint.id;

        const questionType = currentQuestion.type;
        let answers = [];
        const hasScore =
          questionType === "checkbox" ||
          questionType === "select" ||
          questionType === "radio";

        if (hasScore) {
          const answerIds = this.answers[this.currentQuestion.id];
          const answerIdsArray = Array.isArray(answerIds)
            ? answerIds
            : [answerIds];

          answers = currentQuestion.responses.filter(item => {
            return answerIdsArray.includes(item.id);
          });

          const score = answers.reduce((acc, item) => {
            return acc + item.risk_score;
          }, 0);

          this.scores[this.currentQuestion.id] = score;
        }

        if (ansBlueprint.type === "text" || ansBlueprint.type === "textarea") {
          let textAnswer = {
            type: ansBlueprint.type,
            response: _.get(this.answers, ansBlueprint.question_id)
          };
          ansBlueprint.responses = [];
          ansBlueprint.responses.push(textAnswer);
        } else {
          ansBlueprint.responses = answers.slice();
          // rename `content` key to `response`
          ansBlueprint.responses.forEach(res => {
            res.response = res.content;
            // delete res.content;
          });
        }
        this.newAnswers.push(ansBlueprint);

        // Update total score
        this.updateTotalScore();

        this.updateQuestionList();

        var infoReport = {
          continueReport: {
            quiz_continue: this.course.id,
            dataBackup: {
              chapter: this.$route.query.chapter,
              page: this.$route.query.page,
              data: []
            }
          }
        };

        infoReport.continueReport.dataBackup.data.push({
          answers: this.answers,
          currentQuestionIndex: this.currentQuestionIndex,
          newAnswers: this.newAnswers,
          quizIntro: this.quizIntro,
          quizId: this.quiz.id,
          quizQuestions: this.quizQuestions,
          questionList: this.questionList,
          workerGroupSelected: this.workerGroupSelected,
          firstQuizCompleted: this.firstQuizCompleted,
          scores: this.scores,
          reportId: this.reportId ? this.reportId : this.$route.query.cr
        });

        if (
          this.crData &&
          JSON.parse(this.crData.data.report.debug_data).prevCompletedQuiz
        ) {
          infoReport.continueReport.savedBackup = JSON.parse(
            this.crData.data.report.debug_data
          ).prevCompletedQuiz;
        } else if (
          this.crData &&
          JSON.parse(this.crData.data.report.debug_data).continueReport
            .savedBackup
        ) {
          infoReport.continueReport.savedBackup = JSON.parse(
            this.crData.data.report.debug_data
          ).continueReport.savedBackup;
        }

        const continueReportData = {
          debug_data: infoReport
        };

        // Move on to next question
        if (this.currentQuestionIndex + 1 === this.questionList.length) {
          ReportService.update({
            id: this.reportId ? this.reportId : this.$route.query.cr,
            data: continueReportData
          })
            .then(() => {
              // Quiz finished
              this.clearBackup();
              this.quizCompleted();
            })
            .catch(e => {
              this.loaderQuiz = false;
              console.log(e);
              console.log("error");
            });
        } else {
          ReportService.update({
            id: this.reportId ? this.reportId : this.$route.query.cr,
            data: continueReportData
          })
            .then(() => {
              this.loaderQuiz = false;
              this.currentQuestionIndex++;
              this.saveBackup();
            })
            .catch(e => {
              this.loaderQuiz = false;
              console.log(e);
              console.log("error");
            });
        }
        this.$refs.form.resetValidation();
      }
    },
    async quizCompleted() {
      this.loaderQuiz = true;

      if (this.$route.query.cr) {
        const responseReport = await ReportService.getExtendedReport({
          id: this.$route.query.cr
        });

        this.quizQuestions = false;
        this.quizResults = true;
        this.updateTotalScore();
        const riskLevel = this.quiz.risk_levels?.filter(item => {
          return (
            this.totalScore >= item.min_score &&
            this.totalScore < item.max_score
          );
        });
        if (riskLevel.length === 0) {
          const defaultRiskResult = [
            { profile_name: "Unknown", alert_type: "info" }
          ];
          this.riskResult = defaultRiskResult[0];
        } else {
          this.riskResult = riskLevel[0];
        }

        if (
          Object.keys(responseReport.data.quiz).findIndex(
            e => e == this.quiz.id
          ) == -1
        ) {
          const data = {
            report_id: this.reportId ? this.reportId : this.$route.query.cr,
            quiz_id: this.quiz.id,
            results: this.newAnswers
          };
          await ResultsService.submitResult({
            data: data
          });
        }

        var reportStatus = {
          status: "In Progress"
        };

        if (
          JSON.parse(this.crData.data.report.debug_data).continueReport &&
          JSON.parse(this.crData.data.report.debug_data).continueReport
            .savedBackup
        ) {
          this.calcFinalRiskAssessment();
          this.$store.dispatch("quizStatus/complete", {
            id: this.quiz.id,
            results: this.newAnswers,
            reportId: this.reportId,
            quizName: this.quiz.name,
            riskResult: this.riskResult,
            riskScore: this.totalScore
          });
          if (this.finalQuiz) {
            reportStatus.status = "Submitted";
            await ReportService.update({
              id: this.reportId ? this.reportId : this.$route.query.cr,
              data: reportStatus
            });
          } else {
            reportStatus.status = "In Progress";
          }
          //map on saved quizes and check if is in myquizzes
          //if not, then push
          Object.values(
            JSON.parse(this.crData.data.report.debug_data).continueReport
              .savedBackup
          ).forEach(e => {
            let indexQuiz = this.myQuizzes.findIndex(
              i => i.quizId === e.quizId
            );
            if (indexQuiz < 0) {
              //is not there then push
              this.$store.dispatch("quizStatus/complete", {
                id: e.quizId,
                results: e.newAnswers,
                reportId: e.reportId,
                quizName: e.quizName,
                riskResult: e.riskResult,
                riskScore: e.totalScore
              });
            }
          });
        } else if (
          JSON.parse(this.crData.data.report.debug_data).prevCompletedQuiz
        ) {
          const responseReport = await ReportService.getExtendedReport({
            id: this.$route.query.cr
          });
          this.crData = responseReport;
          this.calcFinalRiskAssessment();
          this.$store.dispatch("quizStatus/complete", {
            id: this.quiz.id,
            results: this.newAnswers,
            reportId: this.reportId,
            quizName: this.quiz.name,
            riskResult: this.riskResult,
            riskScore: this.totalScore
          });
          if (this.finalQuiz) {
            reportStatus.status = "Submitted";
            await ReportService.update({
              id: this.reportId ? this.reportId : this.$route.query.cr,
              data: reportStatus
            });
          }
        } else {
          this.$store.dispatch("quizStatus/complete", {
            id: this.quiz.id,
            results: this.newAnswers,
            reportId: this.reportId,
            quizName: this.quiz.name,
            riskResult: this.riskResult,
            riskScore: this.totalScore
          });
          reportStatus.status = "In Progress";
          let quizKeyName = this.quiz.id.toString();

          let infoReport = {
            prevCompletedQuiz: {}
          };
          infoReport.prevCompletedQuiz[quizKeyName] = {
            quiz_continue: this.course.id,
            chapter: this.$route.query.chapter,
            page: this.$route.query.page,
            answers: this.answers,
            currentQuestionIndex: this.currentQuestionIndex,
            newAnswers: this.newAnswers,
            quizIntro: this.quizIntro,
            quizId: this.quiz.id,
            quizQuestions: this.quizQuestions,
            questionList: this.questionList,
            totalScore: this.totalScore,
            workerGroupSelected: this.workerGroupSelected,
            firstQuizCompleted: this.firstQuizCompleted,
            scores: this.scores,
            reportId: this.reportId ? this.reportId : this.$route.query.cr,
            quizName: this.quiz.name,
            riskResult: this.riskResult
          };

          const continueReportData = {
            debug_data: infoReport
          };
          ReportService.update({
            id: this.reportId ? this.reportId : this.$route.query.cr,
            data: continueReportData
          });
        }
      } else {
        //If first quiz, rease a new report id and also save in vuex
        if (this.firstQuiz) {
          const riskLevel = this.quiz.risk_levels?.filter(item => {
            return (
              this.totalScore >= item.min_score &&
              this.totalScore < item.max_score
            );
          });
          if (riskLevel.length === 0) {
            const defaultRiskResult = [
              { profile_name: "Unknown", alert_type: "info" }
            ];
            this.riskResult = defaultRiskResult[0];
          } else {
            this.riskResult = riskLevel[0];
          }

          this.$store.commit("changeFirstQuizStatus", true);

          let quizKeyName = this.quiz.id.toString();

          let infoReport = {
            prevCompletedQuiz: {}
          };
          infoReport.prevCompletedQuiz[quizKeyName] = {
            quiz_continue: this.course.id,
            chapter: this.$route.query.chapter,
            page: this.$route.query.page,
            answers: this.answers,
            currentQuestionIndex: this.currentQuestionIndex,
            newAnswers: this.newAnswers,
            quizIntro: this.quizIntro,
            quizId: this.quiz.id,
            quizQuestions: this.quizQuestions,
            questionList: this.questionList,
            totalScore: this.totalScore,
            workerGroupSelected: this.workerGroupSelected,
            firstQuizCompleted: this.firstQuizCompleted,
            scores: this.scores,
            reportId: this.reportId ? this.reportId : this.$route.query.cr,
            quizName: this.quiz.name,
            riskResult: this.riskResult
          };

          const continueReportData = {
            debug_data: infoReport
          };
          ReportService.update({
            id: this.reportId ? this.reportId : this.$route.query.cr,
            data: continueReportData
          });

          this.$store.dispatch("quizStatus/createReport", this.reportId);
        } else {
          this.$store.commit("changeFirstQuizStatus", false);
          // Not the first quiz - get stored report id from vuex
          this.reportId = this.storedReport;
        }

        const riskLevel = this.quiz.risk_levels?.filter(item => {
          return (
            this.totalScore >= item.min_score &&
            this.totalScore < item.max_score
          );
        });

        if (riskLevel.length === 0) {
          const defaultRiskResult = [
            { profile_name: "Unknown", alert_type: "info" }
          ];
          this.riskResult = defaultRiskResult[0];
        } else {
          this.riskResult = riskLevel[0];
        }

        // Store the completed quiz in VueX
        this.$store
          .dispatch("quizStatus/complete", {
            id: this.quiz.id,
            results: this.newAnswers,
            reportId: this.reportId,
            quizName: this.quiz.name,
            riskResult: this.riskResult,
            riskScore: this.totalScore
          })
          .then(
            () => {
              this.quizQuestions = false;
              this.quizResults = true;
            },
            error => {
              console.log(error);
            }
          );

        //POST answers to results endpoint
        const data = {
          report_id: this.reportId,
          quiz_id: this.quiz.id,
          results: this.newAnswers
        };

        await ResultsService.submitResult({
          data: data
        });

        //GET users report from report endpoint

        const usersReportId = this.reportId;

        const reportResponse = await ReportService.getUserReport({
          id: usersReportId
        });

        this.submittedReport = reportResponse.data;

        if (this.finalQuiz) {
          //UPDATE report status
          const reportStatus = {
            status: "Submitted"
          };
          await ReportService.update({
            id: usersReportId,
            data: reportStatus
          });
        } else {
          //UPDATE report status
          const reportStatus = {
            status: "In Progress"
          };
          await ReportService.update({
            id: usersReportId,
            data: reportStatus
          });
        }

        if (
          this.finalQuiz &&
          this.myQuizzes[0] &&
          this.myQuizzes[0].quizRiskTotal
        ) {
          // calculate final score
          this.calcFinalRiskAssessment();
        }
      }

      this.loaderQuiz = false;
    },
    //Previous Button
    previousHandler: function() {
      this.currentQuestionIndex--;
      this.newAnswers.pop();
    },
    //Format select field values
    formatSelectOptions: function(answers) {
      return answers.map(item => {
        return {
          text: item.name,
          value: item.id
        };
      });
    }
  }
};
</script>
<style>
html {
  color: #34a6c6;
}

.box-information p span {
  background-color: transparent !important;
}

.box-information p span a {
  color: rgb(0, 0, 238) !important;
}
</style>
